<template>
	<!-- nav -->
	<nav class="nav nav--fit">
		<div class="nav__wrap">
			<a href="#" class="nav__btn nav__btn--prev">
				<i class="icon icon icon-left">이전 페이지</i>
			</a>
			<p class="nav__item">
				<span class="nav__text">
					이벤트 &middot; 기획전 
				</span>
			</p>
			<a class="nav__btn nav__btn--map">
			</a>
		</div>
	</nav>
	<!-- //nav-->
	<div id="container" class="container container--fit">
		<div id="sub" class="sub event">
			<!-- [D] 활성화된 tab에 active 클래스 추가 -->
			<section class="listcartegory">
				<div class="box">
					<div class="tab tab--half">
						<a href="#" class="active">이벤트</a>
						<a href="#">기획전</a>
					</div>
				</div>
			</section>
			<!-- content -->
			<section class="content">
				<div class="centered centered--large" style="height:calc(100vh - 175px)">
					<div class="centered__wrap">
						<div class="textbox">
							<div class="textbox__icon">
								<img src="@/assets/images/img_noevent.png" alt="현재 진행중인 이벤트가 없어요" style="width: 64px;">
							</div>
							<h3 class="textbox__title">현재 진행중인<br> 이벤트가 없어요</h3>
						</div>
					</div>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
</template>